import { getCached, post } from './crossover';
export { login, refreshAccess } from './crossover';

const Http = { get: getCached, post };

/**
 * Crossover is a basketball lineups and organization system used to analyze players,
 * games, and leagues. Crossover is developed by the team at Pivot Analysis.
 *
 * Documentation for the API is available on our evaluation instance at
 * https://www.swingman.space. Authentication is required to view our documentation.
 *
 * @module Crossover
 */

const apiUrl = path => path;

/**
 * Request a password reset link
 * @param {*} email
 * @return {Promise<>}
 */
export function requestResetPasswordEmail(email, http = Http) {
  return http.post(apiUrl(`/forgot-password/`), { email });
}

/**
 * Reset a password
 * @param {*} password
 * @return {Promise<>}
 */
export function requestResetPassword(token, password, http = Http) {
  return http.post(apiUrl(`/reset-password/`), { token, password });
}

/**
 * Get information of a single team
 */
export function fetchTeam(token, teamId, http = Http) {
  const url = apiUrl(`/team/${teamId}`);

  return http.get(url);
}

/**
 * Get the list of teams
 * @return {Promise<Team[]>}
 */
export function fetchTeams(
  token,
  seasonId,
  page = 0,
  pageSize = 30,
  http = Http,
) {
  const url = `/teams?player_team__season=${seasonId}&limit=${pageSize}&ordering=-preferred
    &offset=${page * pageSize}`;

  return http.get(url).then(response => {
    const data = response;
    data.currentPage = page;
    data.pageSize = pageSize;
    return data;
  });
}

/**
 * Get a list of lineups for a given team
 * @param {String} teamId
 * @return {Promise<Lineup[]>}
 */
export function fetchLineupSummaries(token, leagueId, teamId, http = Http) {
  const url = apiUrl(
    `/league/${leagueId}/team/${teamId}/lineups/summary?min_possessions=10`,
  );
  return http.get(url).then(response => ({
    ...response,
  }));
}

/**
 * Get a list of lineups for analysis for a given team
 * @param {String} teamId
 * @return {Promise<Lineup[]>}
 */
export function fetchLineupAnalysis(
  token,
  leagueId,
  seasonId,
  teamId,
  query,
  http = Http,
) {
  const path = apiUrl(`/league/${leagueId}/team/${teamId}/lineups/analysis`);
  const url = query ? `${path}?${query}` : path;
  return http.get(url);
}

/**
 * Get a list of games for a given team
 * @param {String} teamId
 * @return {Promise<Lineup[]>}
 */
export function fetchGames(
  token,
  teamId,
  page = 0,
  pageSize = 15,
  http = Http,
) {
  // FIXME: add gameId to stats
  // const url = apiUrl(`/team/${teamId}/games/stats`);
  const url = apiUrl(
    `/team/${teamId}/games/stats?ordering=-start_time&limit=${pageSize}&offset=${page *
      pageSize}`,
  );
  return http.get(url).then(response => {
    response.currentPage = page;
    response.pageSize = pageSize;
    response.teamId = teamId;
    return response;
  });
}

/**
 * Get stats of a game (GameExplorer)
 * @param {String} gameId
 * @return {Promise<Lineup[]>}
 */
export function fetchGameStats(token, gameId, http = Http) {
  const url = apiUrl(`/stats/game/${gameId}/lineup`);

  return http.get(url).then(response => ({
    id: gameId,
    ...response,
  }));
}

/**
 * Get the last game of a given team
 */
export function fetchLastGame(token, teamId, http = Http) {
  const url = `/team/${teamId}/games?ordering=-start_time&limit=1`;
  return http.get(url).then(response => ({ teamId, ...response.results[0] }));
}

/**
 * Get a list of combinations for a given team
 * @param {String} teamId
 * @param {String} seasonId
 * @return {Promise<Lineup[]>}
 */
export function fetchCombinations(token, teamId, seasonId, http = Http) {
  const url = apiUrl(
    `/stats/season/${seasonId}/team/${teamId}/combosummary?min_possessions=100`,
  );

  return http.get(url);
}

/**
 * Get a list of combinations for a given team
 * @param {String} teamId
 * @param {String} seasonId
 * @return {Promise<Lineup[]>}
 */
export function fetchCombinationsExplorer(
  token,
  teamId,
  seasonId,
  query,
  http = Http,
) {
  const url = apiUrl(
    `/stats/season/${seasonId}/team/${teamId}/combos?${query}`,
  );

  return http.get(url).then(response => response.combos);
}

/**
 * Get a list of leagues
 * @return {Promise<League[]>}
 */
export function fetchLeagues(token, http = Http) {
  const url = apiUrl(`/leagues?limit=1000`);

  return http.get(url);
}

/**
 * Get a list of seasons
 * @return {Promise<Season[]>}
 */
export function fetchSeasons(token, leagueId, http = Http) {
  const url = `/seasons?stages__league=${leagueId}&limit=1000`;

  return http.get(url);
}

/**
 * Get onOff metrics
 * @param {String} teamId
 * @param {String} seasonId
 * @return {Promise<Lineup[]>}
 */
export function fetchOnOffMetrics(token, teamId, seasonId, http = Http) {
  const url = `/stats/season/${seasonId}/team/${teamId}/ratings`;

  return http.get(url).then(response => response.ratings);
}
