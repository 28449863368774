import preval from 'preval.macro';

const API_URLS = {
  // production: 'https://api.pivotanalysis.app/api',
  production: 'https://api.pivotanalysis.app/api',
  development: '/api',
  staging: 'https://www.swingman.space/api',
  next: 'https://api.pivotlabs.net/api',
};

export const version = preval`module.exports = new Date().toString();`;
export const isProduction = process.env.NODE_ENV === 'production';
export const deployStage = process.env.REACT_APP_STAGE;
export const baseUrl = API_URLS[deployStage] || API_URLS['production'];
