import debug from 'debug';
import { baseUrl as BASE_URL } from '../config';

const log = debug('pivot:crossover');

const CACHE = {};
const CROSSOVER_TOKEN = 'crossover:token';
const CROSSOVER_ACCESS_TOKEN = 'crossover:access';

const credentials = {
  refresh: localStorage.getItem(CROSSOVER_TOKEN),
  access: null,
};
const headers = {
  Authorization: '',
};
let refreshPromise = null;

const toJson = response => {
  if (response.ok) {
    return response.json();
  } else {
    throw response;
  }
};

log('BASE:%s', BASE_URL);
log('CREDENTIALS %o', credentials);

export function getSession() {
  return { refresh: credentials.refresh };
}

export function logout() {
  credentials.refresh = credentials.access = null;
  localStorage.setItem(CROSSOVER_TOKEN, '');
  localStorage.setItem(CROSSOVER_ACCESS_TOKEN, '');
}

function storeCredentials(response) {
  if (response.refresh && response.refresh !== credentials.refresh) {
    log('New refresh!', response.refresh);
    credentials.refresh = response.refresh;
    localStorage.setItem(CROSSOVER_TOKEN, credentials.refresh);
  }
  credentials.access = response.access;
  localStorage.setItem(CROSSOVER_ACCESS_TOKEN, btoa(credentials.access));
  headers.Authorization = `Bearer ${credentials.access}`;
  log('New credentials!', credentials);
  return credentials;
}

export function getAccessToken() {
  return localStorage.getItem(CROSSOVER_ACCESS_TOKEN);
}

export async function get(url) {
  if (!url) return Promise.resolve(undefined);

  await access();
  log('GET %s', url);
  return getCached(url).catch(error => {
    const data = error.response && error.response.data;
    if (data && data.code === 'token_not_valid') {
      refreshAccess().then(() => getCached(url));
    } else {
      return Promise.reject(error);
    }
  });
}

export function fromCache(url) {
  log('FROM CACHE: %s', url);
  return CACHE[url];
}

export function getCached(url) {
  const cached = CACHE[url];
  if (cached) {
    log('GET [CACHED] %s', url);
    return Promise.resolve(cached);
  }

  log('GET %s', url);
  return fetch(BASE_URL + url, { headers })
    .then(toJson)
    .then(data => {
      CACHE[url] = data;
      return data;
    });
}

export function post(url, data) {
  log('POST %s', url);
  return fetch(BASE_URL + url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(data),
  }).then(toJson);
}

/**
 * Login into the API
 * @param {*} email
 * @param {*} password
 * @return {Promise<Credentials>}
 */
export function login(email, password) {
  return post(`/token/`, { email, password })
    .then(storeCredentials)
    .catch(error => {
      if (error.status === 401) throw Error('Invalid email or password');
      else throw error;
    });
}

function access() {
  if (refreshPromise) return refreshPromise;
  else if (credentials.refresh) return Promise.resolve();
  else return Promise.reject('No refresh token');
}

export async function refreshAccess() {
  await access();

  refreshPromise = post(`/token/refresh/`, {
    refresh: credentials.refresh,
  }).then(response => {
    storeCredentials(response);
    refreshPromise = null;
    return credentials;
  });
  return refreshPromise;
}
