import { init as initSentry } from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import { version } from './config';
import { refreshAccess } from './lib/api';
import { logout } from './lib/crossover';

// eslint-disable-next-line no-console
console.log('PIVOT', version);

if (process.env.NODE_ENV === 'production') {
  initSentry({
    dsn: 'https://c4a89005c6e8411d9327632a272bcbcf@sentry.io/1491826',
  });
}

refreshAccess().catch(err => {
  logout();
});

import('./App').then(App => {
  App.initApplication();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
